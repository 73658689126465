import React from "react"
import Layout from "../components/layout"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../components/seo"
import { PageHeader, Services } from "../components/sections"
import {
  howVoice,
  inbound,
  simonly,
  sip,
  voiceMobility,
} from "../images/voiceandmobility"
import {
  BlackSection,
  DetailSection,
  IntroSection,
  OfferSection,
  Points,
} from "../components/structure"
import { AboutCard } from "../components/elements"

const weOffer = [
  {
    id: 1,
    src: sip,
    title: "SIP",
    content:
      "We utilise session initiation protocol (SIP) to connect your unified communications system or PBX to the public switched telephone network. This process allows you to initiate and maintain real-time sessions on voice, video, and messaging applications, refining the way your business communicates. ",
    order: 0,
  },
  {
    id: 2,
    src: inbound,
    title: "Inbound Number",
    content:
      "Our inbound number solutions make your business more accessible to your customers with adaptable call routing options. Forward incoming calls to any of your business devices and enjoy added benefits like time-based routing and outbound dialing.",
    order: 1,
  },
  {
    id: 3,
    src: simonly,
    title: "SIM-Only Mobile",
    content:
      "Expensive phone repayments can take a significant toll on your business. That’s where our sim-only mobile plans come in. Our phone plans are cost-effective and flexible while still covering talk, text, and data usage so your business can communicate efficiently without the fear of overwhelming fees.",
    order: 0,
  },
]

const points = [
  {
    id: 1,
    point:
      "Connecting your personnel and allowing them to communicate effectively",
  },
  {
    id: 2,
    point: "Fostering collaboration between your business and other entities",
  },
  {
    id: 3,
    point: "Making your business more accessible to your customers",
  },
  {
    id: 4,
    point: "Helping you to run your business remotely",
  },
  {
    id: 5,
    point: "Cutting costs associated with unnecessary phone plans",
  },
]
export default function VoiceMobility() {
  const data = useStaticQuery(graphql`
    query {
      index: file(relativePath: { eq: "headers/voiceProduct.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const index = [
    data.index.childImageSharp.fluid,
    `linear-gradient(0deg,
      rgba(0, 0, 0, 0.30),
      rgba(0, 0, 0, 0.30))`,
  ].reverse()
  return (
    <Layout>
      <SEO title="Voice & Mobility" />
      <PageHeader
        fluid={index}
        catchy="Voice & Mobility"
        subtitle="Grow your business through communication and collaboration"
        title="Improve your company’s communication today."
      />
      <IntroSection
        title="Voice and mobility"
        content="Voice and mobility solutions keep the communication strong throughout your business. With effective voice solutions at your disposal, it’s easier than ever to collaborate with personnel and keep your customers satisfied. We use proven tools and strategies, including session initiation protocol, inbound numbers, and sim-only mobile plans to improve the lines of communication within your business."
        src={voiceMobility}
      />
      <BlackSection title="Leave your network to us" />
      <IntroSection
        title="How we will help you?"
        content="Effective communication is key for a successful business. Our voice and mobility solutions make it easier than ever for your business to connect and collaborate, both internally and externally. We utilise powerful products and methods such as cost-effective mobile plans, inbound and outbound dialling features, and session initiation protocol to help your business communicate clearly and connect with the right people. Social distancing and physical restrictions are no problem when your business has a strong communication system enabling it to interact, collaborate, and triumph. "
        src={howVoice}
      />
      <OfferSection title="What we offer:">
        {weOffer.map((item, id) => (
          <AboutCard
            key={item.id}
            order={item.order}
            src={item.src}
            to={item.to}
            title={item.title}
            content={item.content}
          />
        ))}
      </OfferSection>
      <DetailSection paragraph1="You can improve the way your people interact, communicate, and collaborate through our voice and mobility services. We connect the communication systems in your workplace and ensure that they are helping your business to reach its full potential, regardless of the effects of Covid-19. By using SIP, inbound number dialling and outbound dialling, and cost-effective mobile plans such as a sim-only plan, you’ll benefit your business in a plethora of ways. These include:">
        {points.map((item, id) => (
          <Points key={item.id}>{item.point}</Points>
        ))}
      </DetailSection>
      <Services />
      <BlackSection title="Call us today for a free quote on our managed network services. Your business deserves it!" />
    </Layout>
  )
}
